import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tag } from '@entur/layout';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { ClockIcon } from '@entur/icons';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const time = new Date().getHours() + 1 + \':\' + new Date().getMinutes()\n  return (\n    <div style={{ display: \'flex\', gap: \'2rem\' }}>\n      <Tag>\n        <ClockIcon aria-label=\"Klokke\" />\n      </Tag>\n      <Tag>\n        <ClockIcon inline aria-label=\"Tidspunkt:\" /> {time}\n      </Tag>\n      <Tag>Kollektivselskap</Tag>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Tag,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      ClockIcon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const time = new Date().getHours() + 1 + ':' + new Date().getMinutes();
        return <div style={{
          display: 'flex',
          gap: '2rem'
        }}>
        <Tag mdxType="Tag">
          <ClockIcon aria-label="Klokke" mdxType="ClockIcon" />
        </Tag>
        <Tag mdxType="Tag">
          <ClockIcon inline aria-label="Tidspunkt:" mdxType="ClockIcon" /> {time}
        </Tag>
        <Tag mdxType="Tag">Kollektivselskap</Tag>
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "tag"
    }}>{`Tag`}</h3>
    <ImportStatement imports="Tag" mdxType="ImportStatement" />
    <Props of={Tag} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Tags brukes for å kategorisere eller organisere elementer ved hjelp av nøkkelord som beskriver dem. Bruk tags når innholdet har mange kategorier, og brukeren trenger en måte å skille mellom dem. For enkel skanning av de ulike, bør teksten ikke være på mer enn to ord.
For filtrering der brukeren kan filtrere data og bare se elementer innenfor ønsket kategori kan du bruke Filter Chips eller Tags Chips komponenten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      